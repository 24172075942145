<template>
  <div class="multimediaTips">
    <div class="multimediaTipsInner">
      <div class="leftBox">
        <!-- <div class="chatroomIcon"></div> -->
        <div class="queueBox">
          <div
            class="queueItem"
            v-for="(item, index) in meetingMember"
            :key="index + 'queueItem'"
            @click="jumpUserInfo(item.id)"
          >
            <img :src="formartImageUrl(item.profilePic)" />
          </div>
          <div
            class="queueItem_omit"
            v-if="
              members.length > 8 &&
                this.$store.state.imStore.IMWindowSize !== 'full'
            "
          >
            <div class="omit_inner">
              <span class="connectingAnimation" @click="showFullWindow">
                <span class="point point_1"></span>
                <span class="point point_2"></span>
                <span class="point point_3"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <!-- <div class="innerButton" @click="joinChatRoom"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import {
  handleGroupMeetingData,
  agreedInvitation,
} from "../coreOperations/local";
import * as RESTApi from "@/api/rest";
import { meetingStartRecord } from "@/components/common/multimedia_meetingmode/coreOperations/local"
export default {
  name: "chatRoomQueue",
  inject: ["control"],
  data() {
    return {
      members: [],
    };
  },
  computed: {
    // 当前群对应的会议信息
    meetingInfo() {
      return this.$store.state.multimedia.ImMeetingInfo;
    },
    meetingMember() {
      this.members = this.meetingInfo.members.filter((e) => {
        return e.joined === true;
      });
      return this.$store.state.imStore.IMWindowSize === "full"
        ? this.members
        : this.members.slice(0, 8);
    },
  },
  methods: {
    // 点击省略号查看大屏
    showFullWindow() {
      this.$store.commit("setIMWindowSize", "full");
    },
    // 跳转到对方详情页面
    jumpUserInfo(id) {
      this.jumpPage({
        name: "personalCenter",
        params: { id: id },
      });
    },
    // 根据不同会议类型开启并进入聊天室
    async joinChatRoom() {
      // 这样写是为了方便以后切换成5.0.0版本音视频
      this.meetingRoomCommunication();
    },
    // 5.2.0会议时版本
    meetingRoomCommunication() {
      // 如果忙线则禁止开启聊天室
      if (this.$store.state.multimedia_meetingmode.meetingWindowStatus) {
        this.$confirm(this.$t("inTheMeeting"), '提示', {
          customClass: "joinTips",
          confirmButtonText: ' ',
          cancelButtonText: ' ',
          cancelButtonClass: "el-icon-close my-confirm-btn",
          confirmButtonClass: "el-icon-check my-confirm-btn",
          showCancelButton: false,
          type: 'warning',
        }).then(() => {}).catch(() => {});
        return;
      }
      this.$nextTick( async () => {
        let joinParams = {
          deviceType: "web",
          chatType: "groupChat",
          subjectId: this.$store.state.imStore.otherPartyInfo.id,
        };
        // 调用加入会议接口
        let result = await RESTApi.joinMeeting(joinParams);
        if(result.code == 200){
          // 存贮会议发起者的ID
          this.$store.commit("setMeetingCreatorId", this.meetingInfo.creatorId);
          // 设置本次会议已开启多久的时间
          this.$store.commit("setMeetingTimestamp",parseInt(result.data.data.sysCurrentTime) - parseInt(this.meetingInfo.meetingTime));
          // 存储会议房间号
          this.$store.commit("setMeetingChannelName",this.meetingInfo.meetingId);
          // 存储房间群号
          this.$store.commit("setMeetingGroupId",this.meetingInfo.meetingId);
          // 打开会议聊天窗口
          this.$store.commit("setMeetingWindowStatus",true);
        }else{
          this.$message({
            type: "error",
            message: result.message
          })
        }
      })
    },
    // 5.0.0即时通讯版本
    instantMessaging() {
      // 如果忙线则禁止开启聊天室
      if (this.$store.state.multimedia.callBusy) {
        this.$confirm(this.$t("inTheMeeting"), '提示', {
          customClass: "joinTips",
          confirmButtonText: ' ',
          cancelButtonText: ' ',
          cancelButtonClass: "el-icon-close my-confirm-btn",
          confirmButtonClass: "el-icon-check my-confirm-btn",
          showCancelButton: false,
          type: 'warning',
        }).then(() => {}).catch(() => {});
        return;
      }
      // 再次确保当前用户是被邀请的人员
      let beInvited = this.meetingInfo.members.some(
        (e) => e.id === this.$store.getters.userInfo.id
      );
      if (beInvited) {
        this.meetingInfo.isGroup
          ? this.joinGroupMeeting()
          : this.joinSingleMeeting();
      }
    },
    // 加入群体会议
    joinGroupMeeting() {
      // 设置房间号
      this.$store.commit("setChannelName", this.meetingInfo.meetingId);
      // 设置本次会议开启的时间
      this.$store.commit("setMeetingTime", this.meetingInfo.meetingTime);
      handleGroupMeetingData(this.meetingInfo.members)
        .then((handleMembers) => {
          // 存入群信息
          this.$store.commit("setGroupInformation", {
            ...this.control.getCurrentGroupInfo(),
            groupId: this.control.currentContact.id,
          });
          let params = {
            deviceType: "web",
            chatType: "groupChat",
            subjectId: this.$store.state.multimedia.groupInformation.groupId,
          };
          // 调用接听接口，并初始化客户端
          agreedInvitation(params)
            .then((e) => {
              console.log(e);
              // 切换窗口
              this.$store.commit("setActiveOrPassive", true);
              let realMembers = handleMembers.filter(e=>{
                return e.id !== this.$store.getters.userInfo.id
              })
              // 开启群聊窗口
              this.$store.dispatch("commitOpenGroupChatPop", realMembers);
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err
              });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 加入单聊会议
    joinSingleMeeting() {
      // 设置房间号
      this.$store.commit("setChannelName", this.meetingInfo.meetingId);
      // 设置本次会议开启的时间
      this.$store.commit("setMeetingTime", this.meetingInfo.meetingTime);
      // 存入双方信息
      this.$store.commit("setSingleChatInformation", {other:this.control.currentContact,self:this.$store.getters.userInfo});
      let params = {
        deviceType: "web",
        chatType: "chat",
        subjectId:  this.$store.state.multimedia.singleChatInformation.other.id,
      };
      agreedInvitation(params)
        .then((e) => {
          console.log(e);
          // 切换窗口
          this.$store.commit("setActiveOrPassive",true)
          // 开启单人聊天窗口
          this.$store.commit("setSingleChatPop", true);
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err
          });
        });
    },
  },
};
</script>
<style lang="stylus">
.joinTips{
  padding-top: 40px !important;
  .el-message-box__header{
    display none !important;
  }
}
</style>
<style lang="stylus" scoped>
.multimediaTips{
  width 100%;
  height 45px;
  background: #ffffff;
  position absolute;
  top 0;
  left 0;
  z-index 10;
  display flex;
  padding 0 10px;
  flex-direction row;
  align-items center;
  box-sizing border-box;
  justify-content center;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.17);
  .multimediaTipsInner{
    height 30px;
    width 100%;
    display flex;
    position relative;
    flex-direction row;
    align-items center;
    justify-content space-between;
    .leftBox{
      width 100%;
      height 100%;
      display flex;
      padding-right 62px;
      flex-direction row;
      align-items center;
      box-sizing border-box;
      justify-content flex-start;
      .chatroomIcon{
        width 24px;
        height 14px;
        background url("../../../../assets/images/multiplayer/chatroomIcon.png") no-repeat;
        background-size 100% 100%;
      }
      .queueBox{
        height 100%;
        width 100%;
        margin-left 10px;
        text-align left;
        white-space:nowrap
        overflow-y hidden;
        overflow-x auto;
        &::-webkit-scrollbar {
          height 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
          background: rgba(100,100,100,0.2);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          border-radius: 0;
          background: rgba(0,0,0,0.1);
        }
        .queueItem{
          width 30px;
          height 30px;
          overflow hidden;
          display inline-block;
          border-radius 50%;
          background #BCBCBC;
          margin-right 5px;
          img{
            width 100%;
            height 100%;
          }
        }
        .queueItem_omit{
          width 30px;
          height 30px;
          overflow hidden;
          border-radius 50%;
          background transparent;
          display inline-block;
          .omit_inner{
            width 100%;
            height 100%;
            display flex;
            overflow hidden;
            border-radius 50%;
            flex-direction row;
            align-items center;
            justify-content center;
            .connectingAnimation{
              cursor pointer;
              display inline-block;
              height 100%;
              .point{
                display inline-block;
                width 5px;
                height 5px;
                margin-right 3px;
                border-radius 50%;
                background #BCBCBC;
                animation: flashing 1.5s infinite linear;
                &:nth-child(3){
                  margin-right 0px;
                }
              }
              .point_1{
                animation-delay: 0.5s;
              }
              .point_2{
                animation-delay: 1s;
              }
              .point_3{
                animation-delay: 1.5s;
              }
            }
          }
        }
      }
    }
    .rightBox{
      width 47px;
      height 100%;
      display flex;
      position absolute;
      top 0;
      right 0;
      flex-direction row;
      align-items center;
      justify-content center;
      .innerButton{
        width 100%;
        height 23px;
        cursor pointer;
        background url("../../../../assets/images/multiplayer/joinGroupChat.png") no-repeat;
        background-size 100% 100%;
      }
    }
  }
}
@keyframes flashing {
  0% {
    opacity 1;
  }
  50% {
    opacity 0;
  }
  100% {
    opacity 1;
  }
}
</style>
