var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "multimediaTips" }, [
    _c("div", { staticClass: "multimediaTipsInner" }, [
      _c("div", { staticClass: "leftBox" }, [
        _c(
          "div",
          { staticClass: "queueBox" },
          [
            _vm._l(_vm.meetingMember, function(item, index) {
              return _c(
                "div",
                {
                  key: index + "queueItem",
                  staticClass: "queueItem",
                  on: {
                    click: function($event) {
                      return _vm.jumpUserInfo(item.id)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: _vm.formartImageUrl(item.profilePic) }
                  })
                ]
              )
            }),
            _vm.members.length > 8 &&
            this.$store.state.imStore.IMWindowSize !== "full"
              ? _c("div", { staticClass: "queueItem_omit" }, [
                  _c("div", { staticClass: "omit_inner" }, [
                    _c(
                      "span",
                      {
                        staticClass: "connectingAnimation",
                        on: { click: _vm.showFullWindow }
                      },
                      [
                        _c("span", { staticClass: "point point_1" }),
                        _c("span", { staticClass: "point point_2" }),
                        _c("span", { staticClass: "point point_3" })
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      ]),
      _c("div", { staticClass: "rightBox" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }